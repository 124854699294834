<template>
  <login @submit="handleLogin" />
</template>
<script>
import jwt_decode from "jwt-decode";

export default {
  name: "LoginWrapper",
  props: {
    site: null,
  },
  methods: {
    async handleLogin(formData) {
      let authUrl = this.site.settings.authUrl;
      let method = this.site.settings.authAPIMethod;

      if (formData['authId']) {
        let authDef = this.site.settings.extraAuths.find((auth) => auth.key === formData['authId']);
        if (authDef) {
          authUrl = authDef.url;
          method = authDef.method;
        }
      }

      Object.keys(formData).forEach((key) => {
        authUrl = authUrl.replace(`:${key}`, formData[key]);
      });
      const fetchParam = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      if (method) {
        fetchParam["method"] = method;
      }
      if (method === "POST") {
        fetchParam["body"] = JSON.stringify(formData);
      }

      const resp = await fetch(authUrl, fetchParam).then((res) => res.json());
      console.log('resp', resp)
      const { token } = resp;
      window.user = jwt_decode(token);
      let tokenKey = `gg-${this.site.uri}-token`
      if (formData['authId']) {
        tokenKey = `gg-${this.site.uri}-ggAuth${formData['authId']}-token`
      }
      window.localStorage.setItem(tokenKey, token);
      const siteUri = window.location.pathname.slice(1).split("/")[0];
      window.location.href = `/${siteUri}`;
    },
  },
};
</script>
